import React from 'react';

import Layout from '../components/Layout';
import pic4 from '../assets/images/pic04.jpg';
import pic5 from '../assets/images/pic05.jpg';

const IndexPage = () => (
  <Layout fullMenu>
    <section id="wrapper">
      <header>
        <div className="inner">
          <h2>
            <span className="icon fa-soundcloud">&nbsp;</span>
            Soundcloud
          </h2>
          <p>Playlists, Albums, and Stations</p>
        </div>
      </header>

      <div className="wrapper">
        <div className="inner">
          <h3 className="major">New Albums</h3>
          <p>New Releases from Sessions West Artists</p>

          <section className="features">
            <article>
              <h4 className="major">Jeff 'El Jefe' Anderson - 3000 MILES</h4>

              <iframe
                style={{
                  margin: '0 auto',
                  display: 'block',
                }}
                width="100%"
                height="600"
                scrolling="no"
                frameborder="no"
                allow="autoplay"
                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1191230212&color=%23d6a456&auto_play=true&hide_related=false&show_comments=false&show_user=true&show_reposts=false&show_teaser=true&visual=true"
              ></iframe>

              <a
                href="https://soundcloud.com/sessionswest/sets/3000-miles"
                className="special"
              >
                Listen at Soundcloud
              </a>
            </article>

            <article>
              <h4 className="major">Jesse Cravens - The Chase Vol. 1</h4>
              <iframe
                style={{
                  margin: '0 auto',
                  display: 'block',
                }}
                width="100%"
                height="600"
                scrolling="yes"
                frameborder="no"
                allow="autoplay"
                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1172379715&color=%23848484&auto_play=true&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
              ></iframe>

              <a
                href="https://soundcloud.com/sessionswest/sets/jesse-cravens-the-chase-1"
                className="special"
              >
                Listen at Soundcloud
              </a>
            </article>
          </section>

          <h3 className="major">Albums of the Past</h3>
          <p>Past Releases from Sessions West Artists</p>

          <section className="features">
            <article>
              <h4 className="major">The Hugh Fadal Band - Good Clean Living</h4>

              <iframe
                style={{
                  margin: '0 auto',
                  display: 'block',
                }}
                width="100%"
                height="600"
                scrolling="no"
                frameborder="no"
                allow="autoplay"
                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1191784666&color=%23d6a456&auto_play=true&hide_related=false&show_comments=false&show_user=true&show_reposts=false&show_teaser=true&visual=true"
              ></iframe>

              <a
                href="https://soundcloud.com/sessionswest/sets/the-hugh-fadal-band-good-clean"
                className="special"
              >
                Listen at Soundcloud
              </a>
            </article>

            <article>
              <h4 className="major">Jeff 'El Jefe' Anderson - Shine</h4>

              <iframe
                style={{
                  margin: '0 auto',
                  display: 'block',
                }}
                width="100%"
                height="600"
                scrolling="no"
                frameborder="no"
                allow="autoplay"
                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1191828070&color=%23d6a456&auto_play=true&hide_related=false&show_comments=false&show_user=true&show_reposts=false&show_teaser=true&visual=true"
              ></iframe>

              <a
                href="https://soundcloud.com/sessionswest/sets/jeff-el-jefe-anderson-shine-1"
                className="special"
              >
                Listen at Soundcloud
              </a>
            </article>

            <article>
              <h4 className="major">
                Jeff 'El Jefe' Anderson - El Jefe at Sessions West
              </h4>
              <iframe
                style={{
                  margin: '0 auto',
                  display: 'block',
                }}
                width="100%"
                height="600"
                scrolling="yes"
                frameborder="no"
                allow="autoplay"
                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1191802066&color=%23d6a456&auto_play=true&hide_related=false&show_comments=false&show_user=true&show_reposts=false&show_teaser=true&visual=true"
              ></iframe>

              <a
                href="https://soundcloud.com/sessionswest/sets/jeff-el-jefe-anderson-jefe-at"
                className="special"
              >
                Listen at Soundcloud
              </a>
            </article>

            <article>
              <h4 className="major">
                Jeff 'El Jefe' Anderson - The Fever Sessions
              </h4>
              <iframe
                style={{
                  margin: '0 auto',
                  display: 'block',
                }}
                width="100%"
                height="600"
                scrolling="yes"
                frameborder="no"
                allow="autoplay"
                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1191790780&color=%23d6a456&auto_play=true&hide_related=false&show_comments=false&show_user=true&show_reposts=false&show_teaser=true&visual=true"
              ></iframe>

              <a
                href="https://soundcloud.com/sessionswest/sets/jeff-el-jefe-anderson-the"
                className="special"
              >
                Listen at Soundcloud
              </a>
            </article>

            <article>
              <h4 className="major">Jeff 'El Jefe' Anderson - El Jefe</h4>
              <iframe
                style={{
                  margin: '0 auto',
                  display: 'block',
                }}
                width="100%"
                height="600"
                scrolling="yes"
                frameborder="no"
                allow="autoplay"
                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1191796579&color=%23d6a456&auto_play=true&hide_related=false&show_comments=false&show_user=true&show_reposts=false&show_teaser=true&visual=true"
              ></iframe>

              <a
                href="https://soundcloud.com/sessionswest/sets/jeff-el-jefe-anderson-el-jefe"
                className="special"
              >
                Listen at Soundcloud
              </a>
            </article>
          </section>
        </div>
      </div>
    </section>
  </Layout>
);

export default IndexPage;
